import { isArray, isObject } from 'lodash'
import SubCategoryBox from 'themes/base/components/category/SubCategoryBox'

export default {
  extends: SubCategoryBox,
  computed: {
    image() {
      let image = this.subCategory.image

      if (isArray(image)) {
        if (image.length > 0 && isObject(image[0]) && 'fileKey' in image[0]) {
          image = image[0].fileKey
        } else {
          image = null
        }
      }

      return image
    },
  },
}
